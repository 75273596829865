import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@root/shared/components/base-component/base-component';
import { appConfiguration$, processedSteps$, stepMap$, theme$ } from '@root/store/app.store';
import { Observable, combineLatest } from 'rxjs';
import { StepMap } from '@root/shared/models/start.model';
import { StepperService } from '../../services/stepper.service';
import { StepPositionEnum } from '../../models/step-position.enum';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Language } from '@root/shared/models/language.model';
import { Lang } from '@root/shared/models/enums/languages.enum';
import { languages$ } from '@root/shared/store/shared/shared.store';
import { ChangeLanguageService } from '@root/shared/services/change-language.service';
import { SharedRepository } from '@root/shared/store/shared/shared.repository';
import { AppConfiguration } from '@root/shared/models/app-configuration.model';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NextStepsComponent } from '../next-steps/next-steps.component';
import { StepperCardComponent } from '../stepper-card/stepper-card.component';
import { PreviousStepsComponent } from '../previous-steps/previous-steps.component';
import { Theme } from '@root/shared/models/theme.model';
import { AsyncPipe, LocationStrategy, NgClass, Location } from '@angular/common';
import { StepperType } from '@root/shared/models/enums/stepper-type.enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PreviousStepsComponent,
    StepperCardComponent,
    NextStepsComponent,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    AsyncPipe,
    NgClass
  ],
})
export class StepperComponent extends BaseComponent implements OnInit {

  currentStepMap: StepMap;
  stepPosition = StepPositionEnum.Active;
  currentStepPosition = '0';
  totalSteps: number;
  languages: Language[];
  lang = Lang;
  title: string
  appConfiguration: AppConfiguration;
  theme$: Observable<Theme> = theme$;
  stepperType = StepperType;
  headerText: SafeHtml;
  icon: string;
  wrapUpStep: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private location: Location,
    private locationStrategy: LocationStrategy,
    private stepperService: StepperService,
    public translate: TranslateService,
    private changeLanguageService: ChangeLanguageService,
    private sharedRepository: SharedRepository,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([appConfiguration$, stepMap$, processedSteps$, theme$])
        .subscribe(([appConfiguration, stepMap, processedSteps, theme]) => {
          this.appConfiguration = appConfiguration;
          if (appConfiguration && stepMap && processedSteps) {
            this.handleStepPosition(appConfiguration, stepMap);

            this.totalSteps = this.stepperService.removeBlockLoaderStep(stepMap).length;
            this.currentStepMap = this.stepperService.getCurrentStep(appConfiguration.stepId, stepMap);
            this.title = `stepper.${this.currentStepMap?.stepDefinition}`;
            this.headerText = this.sanitizer.bypassSecurityTrustHtml(theme.headerText || '');
            this.icon = theme.icon || '';
            this.wrapUpStep = this.currentStepMap.stepName === 'Wrap Up' ? true : false;
            this.cdr.detectChanges();
          }
        })
    );
    this.subscriptions.add(
      languages$.subscribe((languages => {
        if (!this.isEmpty(languages)) {
          this.languages = languages.map(e => {
            if (e.languageId === 2) {
              return {
                ...e, languageName: 'العربية'
              }
            }
            return e;
          });
          this.cdr.detectChanges();
        }
      })
      ));
  }
  handleStepPosition(appConfiguration: AppConfiguration, stepMap: StepMap[]) {
    const insideSplitStep = this.stepperService.insideSplitStep(appConfiguration.stepId, stepMap);
    if (insideSplitStep) {
      this.currentStepPosition = this.stepperService.getStepCurrentPositionInsideSpiltStep(appConfiguration.stepId, this.stepperService.removeBlockLoaderStep(stepMap));
    } else {
      this.currentStepPosition = this.stepperService.getStepCurrentPosition(appConfiguration.stepId, this.stepperService.removeBlockLoaderStep(stepMap));
    }

  }

  onLanguageChanged(languageId: number) {
    const selectedLanguage = this.languages.filter(e => e.languageId === languageId)[0];
    this.sharedRepository.updateSelectedLanguage(selectedLanguage);
    this.changeLanguageService.changeLang(languageId === 1 ? Lang.English : Lang.Arabic);
  }

  onGoBack() {
    if (this.wrapUpStep) {
      history.pushState(null, null, window.location.href);
      this.locationStrategy.onPopState(() => {
        history.pushState(null, null, window.location.href);
      });
    }
    else {
      this.location.back();
    }
    // this.location.back();
    // window.history.back();
  }

}
