<div class="flex flex-col w-full h-full justify-center items-center px-2 sm:px-4 ">
  @if((theme$|async)?.stepperType===stepperType.CircularShape) {
  <div class="flex w-full h-[75%] justify-center items-center ">
    <div class="flex w-[40%] h-full">
      <app-previous-steps></app-previous-steps>
    </div>
    <div class="flex w-[20%] h-full justify-center items-center">
      <app-stepper-card [stepMap]="currentStepMap" [stepPosition]="stepPosition"></app-stepper-card>
    </div>
    <div class="flex w-[40%] h-full">
      <app-next-steps class="flex w-full h-full"></app-next-steps>
    </div>
  </div>}
  <div class="flex w-full justify-center items-center"
    [ngClass]="(theme$|async)?.stepperType===stepperType.CircularShape?'h-[25%] pb-2':'h-full'">
    <div class="flex w-[33.33%] h-full justify-start items-center">
      @if((theme$|async)?.stepperType===stepperType.CircularShape) {
      <span class="text-xxs sm:text-sm">
        {{ 'stepper.status' | translate:{ cc: currentStepPosition, tc: totalSteps } }}
      </span> }
      @if((theme$|async)?.stepperType===stepperType.WebView) {
      <button (click)="onGoBack()" mat-button class="button">
        <span class="material-icons icon mr-2 "> arrow_back </span>
      </button> }
    </div>
    <div class="flex w-[33.33%] h-full justify-center items-center">
      @if((theme$|async)?.stepperType===stepperType.CircularShape) {
      <span class="text-xxs sm:text-sm"> {{ title | translate}}</span> }
      @if((theme$|async)?.stepperType===stepperType.WebView) {
      <span [innerHTML]="headerText"></span> }
    </div>
    <div class="flex w-[33.33%] h-full justify-end items-center">
      <div class="flex justify-end absolute items-center">
        @if((theme$|async)?.stepperType===stepperType.WebView) {
        <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="flex justify-end items-center">
          <img [src]="icon" class="icon-img" alt="icon" />
        </button> }
        @else {
        <button mat-icon-button [matMenuTriggerFor]="languageMenu" class="flex justify-end items-center">
          <mat-icon>language</mat-icon>
        </button>
        }
      </div>
    </div>
  </div>
</div>
<mat-menu #languageMenu="matMenu">
  @for (language of languages; track language) {
  <button class="flex justify-start items-center" (click)="onLanguageChanged(language.languageId)" mat-menu-item>
    <img alt="en" class="w-5 h-5 mx-2" [src]="language.languageFlagImage" />
    <span>{{language.languageName}}</span>
  </button> }
</mat-menu>